import { post, deletes, get, downFile } from 'common/request'
export default {
  // save: params => post('/dept/add', params),
  // delete: params => deletes('/operlog/deleteBatch', params),
  // edit: params => post('/dept/update', params),
  // list: params => get('/operlog/queryList', params),
  // deleteBatch: params => deletes('/operlog/deleteBatch', params),
  // exportXls: params => downFile('/operlog/exportXls', params),
  // // 获取组织机构
  // deptList: args => get('/dept/list', args),
  // // 获取组织机构 树结构
  // deptTreeList: args => get('/dept/queryTree', args)
 // 网格树
 gridTree:args => get('/scGridManagement/getTreeList', args),
  // 获取图谱
  getAtlas:args => get(`/scGridManagement/getGraph?id=${args}`),
}
