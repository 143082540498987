<!--  -->
<template>
  <div>
    <a-row style="height: 100%">
      <a-col :span="4">
          <!-- 左边竖线样式写法 -->
          <div class="titleBox">
        <div class="titleLine" style=""></div>
          <div>网格列表</div>
        </div>
        <div style="margin-top: 3%; padding-right: 2%">
          <el-input
            placeholder="输入关键字进行过滤"
            prefix-icon="el-icon-search"
            v-model="filterText"
          >
          </el-input>
          <el-tree
            class="filter-tree"
            :data="data"
            :props="defaultProps"
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="treeClick"
            :default-checked-keys="checkedkeys"
          >
          </el-tree>
        </div>
      </a-col>
      <a-col
        :span="20"
        style="height: 100%; border-left: 1px solid #d8d8d8; padding: 1%"
      >
        <div style="width: 100%; height: 100%; text-align: center">
          <mapModal ref="map" v-if="showMap"></mapModal>
          <img
            style="margin-top: 10%"
            src="../../../../../assets/community/nodata.png"
            alt=""
            v-else
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import mapModal from "./components/mapModal.vue";
import api from "./api";
export default {
  components: { mapModal },
  data() {
    return {
      // 左树
      filterText: "",
      defaultProps: {
        children: "children",
        label: "label",
      },
      showMap: false,
      mapList: [],
      checkedkeys: [],
      data: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    checkedkeys: {
      // immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },

  computed: {},

  methods: {
    // 树过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 递归处理树结构
    mapChildren(children) {
      if (!children || children.length === 0) {
        return [];
      }
      return children.map((child) => ({
        label: child.name,
        key: child.id,
        value: child.id,
        children: this.mapChildren(child.children), // 递归处理子节点
      }));
    },
    // 获取网格点
    async getgridTree() {
      try {
        const res = await api.gridTree();
        let treeData = res.data.map((res) => {
          return {
            label: res.name,
            key: res.id,
            value: res.id,
            children: this.mapChildren(res.children),
          };
        });
        this.data = treeData;
        this.getgrid(this.data[0].value);
        this.checkedkeys.push(this.data[0].value);
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    // 树点击
    async treeClick(e) {
      if (e.value) {
        this.getgrid(e.value);
      }
    },
    // 查询标绘数据
    async getgrid(id) {
      try {
        const res = await api.getAtlas(id);
        if (res.data === "未标绘") {
          this.$message.warning("该网格点未标绘任何数据");
          this.showMap = false;
          return;
        }
        let arr = [];
        res.data.forEach((item) => {
          arr.push(item.points);
        });
        this.mapList = arr.map((item) => {
          return item.map((it) => {
            return [it.longitude, it.latitude];
          });
        });
        if (this.mapList) {
          this.showMap = true;
          this.$nextTick(() => {
            this.$refs.map.getMap(this.mapList);
          });
        } else {
          this.showMap = false;
        }
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
  },

  created() {},

  mounted() {
    this.getgridTree();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='less' scoped>
.titleBox{
  display: flex;
  align-items: center;
  font-size: 0.08rem;
  font-weight: 700;
  color: #2d3138;
  .titleLine{
    height: 0.09rem;
    width: 0.015rem;
    background-color:#2a5cff; 
    margin-right: 5px;
  }
}

.filter-tree {
  margin-top: 2%;
}

.gridConfig {
  width: 100%;
  height: 0.2rem;
  border-radius: 4px 4px 4px 4px;
  border: 1px dashed #556bff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #556bff;
  cursor: default;
}

/deep/.is-current {
  color: #556bff !important;
}
/deep/.el-tree-node__content {
  height: 0.16rem;
  font-size: 0.35rem !important;
}
/deep/.el-tree-node__content {
  &:hover {
    background-color: #e8eef4;
  }
}
/deep/.el-tree-node.is-current > .el-tree-node__content {
  background-color: #e8eef4 !important;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>